import { useState, React } from 'react';
import Image from '../../partials/media/Image';
import Video from '../../partials/media/Video';
import Colors from '../../../util/Colors';
import Icons from '../../../util/Icons';

const FileColumn = ({index, file}) => {
    const [expanded, setExpanded] = useState(false);

    const renderPreview = () => {
        const name = file.toLowerCase();
        if (name.endsWith('.mp4')) {
            return (
                <Video key={index} src={file} partner={0} controls width="100%"/>
            )
        } else if (name.endsWith('.jpg') || name.endsWith('.png') || name.endsWith('.jpeg') || name.endsWith('.gif')) {
            return (
                <Image alt='' key={index} src={file} partner={0}/>
            )
        } else {
            console.warn(`Unsupported file type: ${file}`);
        }
    }

    return (
        <div onClick={() => setExpanded(!expanded)} className="value-column">
            <div className="column-component" style={{ border: 'none' }}>
                <p className="medium-text" style={{ color: Colors.border }}>{index+1}.</p>
            </div>
            <div className="column-component">
                <p className="medium-text" style={{ color: Colors.text }}>{file.split('/')[file.split('/').length-1]}</p>
                {expanded ? (
                    <div className="expanded-column">
                        <p className="small-text" style={{ color: Colors.text }}><span style={{ color: Colors.border }}>CDN:</span> {file}</p>
                        <p className="small-text" style={{ color: Colors.border }}>Preview:</p>
                        <div onClick={(e) => {
                            e.stopPropagation();
                            window.open(file, '_blank');
                        }}>
                            {renderPreview(file)}
                        </div>
                    </div>
                ) : null}
            </div>
            <div className="column-dropdown" style={{ border: 'none' }}>
                <p className="medium-text" style={{ color: Colors.border }}><i className={expanded ? Icons.ARROW_UP : Icons.ARROW_DOWN}/></p>
            </div>
        </div>
    );
}

export default FileColumn;
