import { useEffect } from 'react';
import Colors from '../../../util/Colors';
import axios from 'axios';
import { useState } from 'react';
import FileColumn from './FileColumn';

const ManageFiles = () => {
    const [data, setData] = useState([]);

    axios.defaults.withCredentials = true;
    
    useEffect(() => {
        document.title = `${process.env.REACT_APP_TITLE} / Manage Files`;
    }, []);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/media`)
        .then((res) => {
            setData(res.data.files);
        })
        .catch((error) => console.error('Error fetching media:', error)); 
    }, []);

    const renderFiles = () => {
        if (!data || data.length === 0) {
            return (
                <p className="large-text" style={{ padding: 50, color: Colors.border }}>No files available - please check back later.</p>
            )
        }

        return data.map((val, index) => (
            <div key={index}>
                <FileColumn index={index} file={val}/>
            </div>
        ));
    }

    return (
        <div className="wrapper border" style={{ borderColor: Colors.border }}>
            <div className="center">
                {renderFiles()}
            </div>
        </div>
    );
};

export default ManageFiles;
