import RoutePaths from "../../../util/RoutePaths";
import Colors from "../../../util/Colors";
import Icons from "../../../util/Icons";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import ProjectTag from "./ProjectTag";
import axios from "axios";

const ProjectComponent = ({data}) => {
    const [tags, setTags] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/tags/${data.projectId}`)
        .then((res) => {
            setTags(res.data);
        })
        .catch((error) => console.error('Error fetching tags:', error));
    }, []);

    return (
        <div className="project-card" onClick={() => navigate(`${RoutePaths.PROJECT}/${data.projectId}`)} style={{ borderColor: Colors.border, '--hoverColor': Colors.base }}>
            <p className="large-text" style={{ color: Colors.text }}>{data.title}
                {tags.map((val) => (
                    <ProjectTag value={val.value} color={Colors.generic.lime}/>
                ))}
            </p>
            <p className="small-text" style={{ color: 'grey' }}>{data.description}</p>
            <button onClick={(e) => {
                e.stopPropagation();
                window.open(data.github, "_blank");
            }} className="link-button" style={{ borderColor: Colors.border, '--hoverColor': Colors.base, color: Colors.text }}><i className={Icons.ARROW_UP_RIGHT}/> Visit github</button>
        </div>
    );
}

export default ProjectComponent;
