import '../../styles/media.css';

import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import Divider from '../partials/generic/Divider';
import Colors from '../../util/Colors';
import axios from 'axios';

const Project = () => {
    const { projectId } = useParams();
    const [components, setComponents] = useState();

    axios.defaults.withCredentials = true;

    useEffect(() => {
        document.title = `${process.env.REACT_APP_TITLE} / Project`;

        processComponents();
    }, [projectId]);

    const processComponents = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/components/${projectId}`)
        .then((res) => {
            setComponents(res.data);
        })
        .catch((error) => console.error('Error fetching project:', error));
    }

    const handleError = (event) => {
        event.target.src = process.env.REACT_APP_IMAGE_FALLBACK_URL;
    };

    return (
        <div className="wrapper border" style={{ borderColor: Colors.border }}>
            {components ? (
                <>
                    {components.map((component, index) => (
                        <div key={index}>
                            {parseInt(component.type) === 0 ? (
                                <p className="center title" style={{ color: Colors.text }}>{component.value}</p>
                            ) : (parseInt(component.type) === 1) ? (
                                <p className="center large-text" style={{ color: 'grey' }}>{component.value}</p>
                            ) : (parseInt(component.type) === 2) ? (
                                <img alt='' className="component-image" src={component.value} onError={handleError}/>
                            ) : (
                                <Divider/>
                            )}
                        </div>
                    ))}
                </>                
            ) : null}
        </div>
    );
};

export default Project;
