import { useEffect, useState, useCallback, useRef } from "react";
import Colors from "../../../util/Colors";

const ResponsiveFont = ({ text, color = Colors.widgets.aqua }) => {
    const [size, setSize] = useState(3.0);
    const [weight, setWeight] = useState(100);
    const textRef = useRef(null);

    const sizeRef = useRef(size);
    const weightRef = useRef(weight);

    const MIN_WEIGHT = 100;
    const MAX_WEIGHT = 700;
    const MIN_SIZE = 3;
    const MAX_SIZE = 6;
    const INTENSITY = 30;

    const handleMouseMove = useCallback((e) => {
        if (textRef.current) {
            const rect = textRef.current.getBoundingClientRect();
            const textX = rect.left + rect.width / 2;
            const textY = rect.top + rect.height / 2;

            const dx = e.clientX - textX;
            const dy = e.clientY - textY;
            const distance = Math.sqrt(dx * dx + dy * dy);

            const scaledDistance = Math.max(0, Math.min(1, 1 - distance / INTENSITY));
            const newSize = MIN_SIZE + scaledDistance * (MAX_SIZE - MIN_SIZE);
            const newWeight = MIN_WEIGHT + scaledDistance * (MAX_WEIGHT - MIN_WEIGHT);

            if (Math.abs(sizeRef.current - newSize) > 0.01 || Math.abs(weightRef.current - newWeight) > 1) {
                sizeRef.current = newSize;
                weightRef.current = newWeight;
                setSize(newSize);
                setWeight(newWeight);
            }
        }
    }, []);

    useEffect(() => {
        window.addEventListener("mousemove", handleMouseMove);
        return () => window.removeEventListener("mousemove", handleMouseMove);
    }, [handleMouseMove]);

    return (
        <p ref={textRef} style={{ 
            color: color.button, 
            fontSize: `${size}em`, 
            fontWeight: weight, 
            transition: "font-size 0.05s ease-out, font-weight 0.05s ease-out" 
        }}>
            {text}
        </p>
    );
};

export default ResponsiveFont;
