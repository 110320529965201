import Colors from '../../../util/Colors';
import ReactPlayer from 'react-player';
import { useState, useRef } from 'react';
import Button from '../../partials/generic/Button';
import { useNavigate } from 'react-router';
import axios from 'axios';
import RoutePaths from '../../../util/RoutePaths';

const UploadFile = () => {
    const [files, setFiles] = useState([]);
    const [previews, setPreviews] = useState([]);
    const [subtitle, setSubtitle] = useState('');

    const imageInputRef = useRef(null);

    const navigate = useNavigate();

    axios.defaults.withCredentials = true;

    const submitFiles = () => {
        if (files.length <= 0) {
            setSubtitle("No files uploaded!");
            return;
        }

        const form = new FormData();

        files.forEach((file) => {
            form.append('media', file);
        });

        axios.post(`${process.env.REACT_APP_API_URL}/add-files`, form)
            .then((response) => {
                if (response.data.success) {
                    navigate(RoutePaths.DASHBOARD);
                }
            })
            .catch((error) => {
                console.error('Add file error:', error);
                setSubtitle('There was a problem processing this request.');
            });
    };

    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);
    
        if (files.length + files.length > parseInt(process.env.REACT_APP_UPLOAD_LIMIT, 10)) {
            setSubtitle(`You can only upload up to ${process.env.REACT_APP_UPLOAD_LIMIT} files.`);
            return;
        }
    
        const validFiles = [];
    
        files.forEach((file) => {
            const isImage = file.type.match(/image\/(jpeg|jpg|png|gif)/);
            const isVideo = file.type.match(/video\/(mp4|webm|ogg)/);
    
            if (!isImage && !isVideo) {
                setSubtitle('Unsupported file types detected, these are not supported on posts or projects.');
            }
    
            const maxFileSize = parseFloat(process.env.REACT_APP_MAX_UPLOAD);
            if (file.size / (1024 * 1024) > maxFileSize) {
                setSubtitle(`File size exceeds ${maxFileSize}MB.`);
                return;
            }
    
            validFiles.push(file);
    
            const reader = new FileReader();
            reader.onloadend = () => {
                const preview = {
                    type: isImage ? 'image' : isVideo ? 'video' : 'unsupported',
                    src: reader.result,
                };
    
                setPreviews((prev) => {
                    if (!prev.some((existing) => existing.src === preview.src)) {
                        return [...prev, preview];
                    }
                    return prev;
                });
            };
            reader.readAsDataURL(file);
        });
    
        setFiles((prev) => {
            const uniqueFiles = validFiles.filter(
                (file) => !prev.some((existing) => existing.name === file.name && existing.size === file.size)
            );
            return [...prev, ...uniqueFiles];
        });
    };    

    return (
        <div className="wrapper border" style={{ borderColor: Colors.border }}>
            <div className="center">
                <p className="title" style={{ color: Colors.text }}>Upload files</p>
                {previews.length > 0 ? (
                    <div className="outlined-view item-container" style={{ borderColor: Colors.border }}>
                        {previews.map((preview, index) => (
                            <div key={index}>
                                {preview.type === 'image' ? (
                                    <img alt='' className="image-preview" src={preview.src}/>
                                ) : preview.type === 'video' ? (
                                    <ReactPlayer className="video" url={preview.src} width="100%" height="100%" loop controls/>
                                ) : null}
                            </div>
                        ))}
                    </div>
                ) : null}
                <div className="item-container">
                    <a onClick={() => imageInputRef.current.click()}><Button text="Upload" action={() => {}}/></a>
                    <input type="file" ref={imageInputRef} multiple onChange={handleFileChange} accept="image/*,video/*" style={{ display: 'none' }}/>
                    <Button text="Submit" action={submitFiles}/>
                </div>
                <p className="medium-text" style={{ color: Colors.generic.red }}>{subtitle}</p>
            </div>
        </div>
    );
};

export default UploadFile;
