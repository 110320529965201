import Tooltip from './Tooltip.js';
import Colors from '../../../util/Colors.js';
import Icons from '../../../util/Icons.js';
import { useState } from 'react';
import { formatDate, formatLongDate } from '../../../util/Utils.js';

const RepoCard = ({repo, color=Colors.widgets.aqua}) => {
    const [collapsed, setCollapsed] = useState(true);

    const toggleCollapse = (event) => {
        event.stopPropagation();
        setCollapsed(!collapsed);
    }

    return (
        <div className="repo-card" style={{ '--hoverColor': Colors.base, borderColor: Colors.border }}>
            <p className="large-text link" style={{ color: color.button }}>{repo.name}</p>
            {repo.description ? (
                <p className="small-text" style={{ color: 'grey' }}>{repo.description.length > 50 && collapsed ? `${repo.description.slice(0, 50)}... ` : `${repo.description} `}
                {repo.description.length > 50 ? (
                    <>
                        {collapsed ? (
                            <span className="link" onClick={toggleCollapse} style={{ color: color.button }}>Read more</span>
                        ) : (
                            <span className="link" onClick={toggleCollapse} style={{ color: color.button }}>Read less</span>
                        )}
                    </>
                ) : null}</p>
            ) : null}
            <br/>
            <div style={{ margin: '5px 0' }}>
                <Tooltip message={`${repo.stargazers_count} stars`}>
                    <span className="small-text" style={{ marginRight: 10, color: 'grey' }}><i className={Icons.HOME_STAR}/> {repo.stargazers_count}</span>
                </Tooltip>
                <Tooltip message={`${repo.forks} forks`}>
                    <span className="small-text" style={{ marginRight: 10, color: 'grey' }}><i className={Icons.HOME_CODE_FORK}/> {repo.forks}</span>
                </Tooltip>
                <Tooltip message={formatLongDate(repo.created_at)}>
                    <span className="small-text" style={{ marginRight: 10, color: 'grey' }}>{` •  ${formatDate(repo.created_at)}`}</span>
                </Tooltip>
            </div>
        </div>
    );
}

export default RepoCard;
