import { useEffect } from 'react';
import Colors from '../../../util/Colors';
import axios from 'axios';
import { useState } from 'react';
import RoutePaths from '../../../util/RoutePaths';

const ManageProjects = () => {
    const [data, setData] = useState([]);

    axios.defaults.withCredentials = true;
    
    useEffect(() => {
        document.title = `${process.env.REACT_APP_TITLE} / Manage Projects`;
    }, []);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/projects`)
        .then((res) => {
            setData(res.data);
        })
        .catch((error) => console.error('Error fetching projects:', error)); 
    }, []);

    const renderProjects = () => {
        if (!data || data.length === 0) {
            return (
                <p className="large-text" style={{ padding: 50, color: Colors.border }}>No logs available - please check back later.</p>
            )
        }

        return data.map((val, index) => (
            <a href={`${RoutePaths.CREATE_PROJECT}/${val.projectId}`}>
                <div key={index} className="value-column">
                    <div className="column-component" style={{ border: 'none' }}>
                        <p className="medium-text" style={{ color: Colors.border }}>{index+1}.</p>
                    </div>
                    <div className="column-component">
                        <p className="medium-text" style={{ color: Colors.text }}>{val.title} - {val.description}</p>
                    </div>
                </div>
            </a>
        ));
    }

    return (
        <div className="wrapper border" style={{ borderColor: Colors.border }}>
            <div className="center">
                {renderProjects()}
            </div>
        </div>
    );
};

export default ManageProjects;
