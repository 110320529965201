import { useEffect, useState } from 'react';
import Input from '../partials/generic/Input';
import Colors from '../../util/Colors';
import Button from '../partials/generic/Button';
import RoutePaths from '../../util/RoutePaths';
import { useNavigate } from 'react-router';
import axios from 'axios';

const Login = ({setIsAuth}) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [subtitle, setSubtitle] = useState('');

    const navigate = useNavigate();

    axios.defaults.withCredentials = true;

    useEffect(() => {
        document.title = `${process.env.REACT_APP_TITLE} / Login`;
    }, []);

    const login = () => {
        axios.post(`${process.env.REACT_APP_API_URL}/login`, {
            username: username,
            password: password
        })
        .then((response) => {
            if (response.data.logged) {
                setIsAuth(true);
                navigate(RoutePaths.DASHBOARD);
            } else {
                if (response.data.timeout) {
                    setSubtitle(`Maximum attempts exceeded, try again in ${Math.round(60-(Date.now() - response.data.timeout)/1000)}s`);
                } else {
                    setSubtitle(`Invalid credentials. Attempt ${response.data.attempts}/5`);
                }
            }
        })
        .catch((error) => {
            console.error('Login error:', error);
            setSubtitle("There was a problem logging in");
        });
    };

    return (
        <div className="wrapper border" style={{ borderColor: Colors.border }}>
            <div className="center">
                <p className="title" style={{ color: Colors.text }}>Login to dashboard</p>
                <div className="outlined-view">
                    <div className="item-container" style={{ justifyContent: 'center', padding: 20 }}>
                        <Input placeholder={"Username"} value={username} setVariable={setUsername}/>
                        <Input type='password' placeholder={"Password"} value={password} setVariable={setPassword}/>
                    </div>
                    {subtitle ? (
                        <p className="small-text" style={{ color: Colors.generic.red }}>{subtitle}</p>
                    ) : null}
                    <Button text={"Login"} action={login}/>
                </div>
            </div>
        </div>
    );
};

export default Login;
