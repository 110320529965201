import { useEffect, useState } from 'react';
import '../../../styles/contributions.css';

import Colors from '../../../util/Colors';
import Tooltip from '../generic/Tooltip';
import Button from '../generic/Button';

const RenderContributions = ({data, color=Colors.widgets.aqua}) => {
    const [contributions, setContributions] = useState([]);
    const [year, setYear] = useState(new Date(Date.now()).getFullYear())

    useEffect(() => {
        if (data && data.contributions) {
            setContributions(data.contributions.filter(val => val.date.startsWith(String(year))));
        }
    }, [data, year]);

    const getColor = (contribute) => {
        if (contribute.level === 0) return color.contribution_level_one;
        if (contribute.level === 1) return color.contribution_level_two;
        else return color.contribution_level_three;
    };

    const groupByWeeks = (contributions) => {
        const weeks = [];
        contributions.forEach((contribute) => {
            const weekIndex = new Date(contribute.date).getWeek();
            if (!weeks[weekIndex]) {
                weeks[weekIndex] = [];
            }
            weeks[weekIndex].push(contribute);
        });
        return weeks;
    };

    Date.prototype.getWeek = function() {
        const firstDayOfYear = new Date(this.getFullYear(), 0, 1);
        const days = Math.floor((this - firstDayOfYear) / (24 * 60 * 60 * 1000));
        return Math.ceil((days + 1) / 7);
    };

    const groupedData = groupByWeeks(contributions);

    return (
        <>
            <div className="contributions-grid">
                {groupedData.map((week, weekIndex) => (
                    <div key={weekIndex} className="week-column">
                        {week.map((contribute, index) => (
                            <Tooltip key={index} width={185} message={`${contribute.count} contributions on ${contribute.date}`}>
                                <div className="contribute-entry" onClick={() => window.open(`https://www.github.com/${process.env.REACT_APP_GITHUB_USERNAME}`)} style={{ backgroundColor: getColor(contribute) }}/>
                            </Tooltip>
                        ))}
                    </div>
                ))}
            </div>
            <div className="xlabel-container">
                <p className="small-text" style={{ color: Colors.border }}>Jan</p>
                <p className="small-text" style={{ color: Colors.border }}>Feb</p>
                <p className="small-text" style={{ color: Colors.border }}>Mar</p>
                <p className="small-text" style={{ color: Colors.border }}>Apr</p>
                <p className="small-text" style={{ color: Colors.border }}>May</p>
                <p className="small-text" style={{ color: Colors.border }}>Jun</p>
                <p className="small-text" style={{ color: Colors.border }}>Jul</p>
                <p className="small-text" style={{ color: Colors.border }}>Aug</p>
                <p className="small-text" style={{ color: Colors.border }}>Sep</p>
                <p className="small-text" style={{ color: Colors.border }}>Oct</p>
                <p className="small-text" style={{ color: Colors.border }}>Nov</p>
                <p className="small-text" style={{ color: Colors.border }}>Dec</p>
            </div>
            {data.total ? (
                <div className="item-container">
                    {Object.keys(data.total).slice(0, 5).map((val, index) => (
                        <div key={index}>
                            <Button action={() => setYear(parseInt(val))} className={`year-button ${year === parseInt(val) ? 'year-selected' : ''}`} text={val} button_color={year == parseInt(val) ? Colors.generic.blue : 'transparent'} hover_color={Colors.generic.blue} rounded={true}/>
                        </div>
                    ))}
            </div>
            ) : null}

        </>
    );
};

export default RenderContributions;
