import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import Button from '../partials/generic/Button.js';
import RoutePaths from '../../util/RoutePaths.js';
import Colors from '../../util/Colors.js';

const NotFound = () => {
    const navigate = useNavigate();
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

    useEffect(() => {
        document.title = `${process.env.REACT_APP_TITLE} / 404`;

        const handleMouseMove = (e) => {
            const { clientX, clientY } = e;
            const { innerWidth, innerHeight } = window;

            const x = (clientX / innerWidth - 0.5) * 2;
            const y = (clientY / innerHeight - 0.5) * 2;

            setMousePosition({ x, y });
        };

        window.addEventListener('mousemove', handleMouseMove);

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    return (
        <div className="center" style={{ marginTop: '100px', textAlign: 'center', position: 'relative' }}>
            <div className="parallax-container">
                <p className="back-layer" style={{ '--textColor': Colors.widgets.aqua.primary, transform: `translate(calc(-50% + ${mousePosition.x * 20}px), calc(-50% + ${mousePosition.y * 20}px))` }}>404</p>
                <p className="front-layer" style={{ '--textColor': Colors.widgets.aqua.primary, transform: `translate(calc(-50% + ${mousePosition.x * -10}px), calc(-50% + ${mousePosition.y * -10}px))` }}>404</p>
            </div>

            <p className="medium-text" style={{ color: 'grey', margin: '20px 0' }}>An error has occurred loading this content - please try another page.</p>
            <Button text="Home" button_color='transparent' hover_color={Colors.generic.blue} rounded={true} action={() => navigate(RoutePaths.HOME)}/>
        </div>
    );
};

export default NotFound;
