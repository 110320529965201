import React, { useState, useRef } from 'react';
import Colors from '../../../util/Colors.js';
import Icons from '../../../util/Icons.js';
import ReactPlayer from 'react-player';
import Divider from '../generic/Divider.js';
import Tooltip from '../generic/Tooltip.js';
import axios from 'axios';

const PostComposer = () => {
    const [isFocused, setIsFocused] = useState(false);
    const [content, setContent] = useState('');
    const [subtitle, setSubtitle] = useState('');
    const [mediaFiles, setMediaFiles] = useState([]);
    const [previews, setPreviews] = useState([]);

    const imageInputRef = useRef(null);

    axios.defaults.withCredentials = true;

    const addPost = () => {
        if (content.length < 2) {
            setSubtitle('Your post must have at least 2 characters!');
            return;
        }

        const form = new FormData();
        form.append('content', content);

        mediaFiles.forEach((file) => {
            form.append('media', file);
        });

        axios.post(`${process.env.REACT_APP_API_URL}/add-post`, form)
            .then((response) => {
                if (response.data.id) {
                    setContent('');
                    setSubtitle('');
                    setIsFocused(false);
                    setMediaFiles([]);
                    setPreviews([]);
                }
            })
            .catch((error) => {
                console.error('Add post error:', error);
                setSubtitle('There was a problem processing this request.');
            });
    };

    const handleChange = (event) => {
        if (event.target.value.length <= process.env.REACT_APP_POST_CHAR_LIMIT) {
            setContent(event.target.value);
            if (event.target.value.trim() !== '') setIsFocused(true);
        }
    };

    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);
    
        if (mediaFiles.length + files.length > parseInt(process.env.REACT_APP_UPLOAD_LIMIT, 10)) {
            setSubtitle(`You can only upload up to ${process.env.REACT_APP_UPLOAD_LIMIT} files.`);
            return;
        }
    
        const validFiles = [];
    
        files.forEach((file) => {
            const isImage = file.type.match(/image\/(jpeg|jpg|png|gif)/);
            const isVideo = file.type.match(/video\/(mp4|webm|ogg)/);
    
            if (!isImage && !isVideo) {
                setSubtitle('Invalid file type. Only images and videos are allowed.');
                return;
            }
    
            const maxFileSize = parseFloat(process.env.REACT_APP_MAX_UPLOAD);
            if (file.size / (1024 * 1024) > maxFileSize) {
                setSubtitle(`File size exceeds ${maxFileSize}MB.`);
                return;
            }
    
            validFiles.push(file);
    
            const reader = new FileReader();
            reader.onloadend = () => {
                const preview = {
                    type: isImage ? 'image' : 'video',
                    src: reader.result,
                };
    
                setPreviews((prev) => {
                    if (!prev.some((existing) => existing.src === preview.src)) {
                        return [...prev, preview];
                    }
                    return prev;
                });
            };
            reader.readAsDataURL(file);
        });
    
        setMediaFiles((prev) => {
            const uniqueFiles = validFiles.filter(
                (file) => !prev.some((existing) => existing.name === file.name && existing.size === file.size)
            );
            return [...prev, ...uniqueFiles];
        });
    };    

    return (
        <>
            <div className="composer-container">
                <img className="composer-profile" alt="" src={process.env.REACT_APP_PROFILE_PICTURE} />
                <textarea className="input-container composer-input medium-text" placeholder="What's on your mind?" value={content} onFocus={() => setIsFocused(true)} onChange={handleChange} style={{ height: '100%', color: Colors.text }}/>
                {isFocused ? (
                    <p className="char-counter" style={{ color: Colors.border }}>{`${content.length}/${process.env.REACT_APP_POST_CHAR_LIMIT}`}</p>
                ) : null}
            </div>

            {isFocused ? (
                <>
                    <div style={{ margin: 10 }}><Divider/></div>
                    <div className="composer-widgets">
                        <Tooltip message="Upload Media">
                            <button className="widget" onClick={() => imageInputRef.current.click()} style={{ '--contentColor': Colors.text, '--hoverColor': Colors.generic.blue }}><i className={`icon ${Icons.GENERIC_IMAGE}`} aria-hidden="true"></i></button>
                            <input type="file" ref={imageInputRef} multiple onChange={handleFileChange} accept="image/*,video/*" style={{ display: 'none' }}/>
                        </Tooltip>

                        <div className="flex-container" style={{ justifyContent: 'flex-end', width: '100%' }}>
                            <Tooltip message={"Post"}>
                                <button className="widget" onClick={addPost} style={{ '--contentColor': Colors.text, '--hoverColor': Colors.generic.lime }}><i className={`icon ${Icons.GENERIC_COMPOSE}`} aria-hidden="true"></i></button>
                            </Tooltip>
                        </div>
                    </div>
                </>
            ) : null}

            {previews.length > 0 ? (
                <div className="outlined-view item-container" style={{ borderColor: Colors.border }}>
                    {previews.map((preview, index) => (
                        <div key={index}>
                            {preview.type === 'image' ? (
                                <img alt='' className="image-preview" src={preview.src}/>
                            ) : (
                                <ReactPlayer className="video" url={preview.src} width="100%" height="100%" loop controls/>
                            )}
                        </div>
                    ))}
                </div>
            ) : null}

            {subtitle ? (
                <>
                    <Divider/>
                    <div className="center">
                        <p className="medium-text" style={{ padding: 5, color: Colors.generic.red }}>
                            {subtitle}
                        </p>
                    </div>
                </>
            ) : null}

            <Divider/>
        </>
    );
};

export default PostComposer;
