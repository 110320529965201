import React, { useEffect } from 'react';
import Colors from '../../util/Colors.js';

const Loading = () => {
    return (
        <div className="center vertical-center">
            <div className="loader" style={{ '--primaryColor': Colors.widgets.aqua.button, '--secondaryColor': Colors.border }}/>
        </div>
    );
}

export default Loading;
