import '../../../styles/navigation.css';

import RoutePaths from '../../../util/RoutePaths';
import Colors from '../../../util/Colors';
import Icons from '../../../util/Icons';
import { useLocation, useNavigate } from 'react-router';
import { useState } from 'react';
import Divider from '../generic/Divider';

function Navigation() {
    const [expanded, setExpanded] = useState(false);

    const location = useLocation();
    const navigate = useNavigate();

    function getParams({value}) {
        const path = location.pathname === value;
        return { color: Colors.text, '--hoverColor': Colors.border, fontWeight: path ? 'bold' : 'normal' };
    }

    return (
        <>
            <p onClick={() => setExpanded(!expanded)} className="navigation-icon navigation-fixed-icon" style={{ color: Colors.text }}><i className={`medium-text ${Icons.ARROW_RIGHT}`}/></p>

            <div className={`navigation-body shadow ${expanded ? 'navigation-body-shown' : 'navigation-body-hidden'}`} style={{ borderColor: Colors.border }}>
                <div className="navigation-title-container">
                    <p onClick={() => setExpanded(!expanded)} className="navigation-icon" style={{ color: Colors.text }}><i className={`medium-text ${Icons.ARROW_LEFT}`}/></p>
                    <p className="small-text" style={{ color: Colors.text }}>Navigation</p>
                </div>

                <Divider/>

                <div className="navigation-text-wrapper">
                    <p onClick={() => navigate(RoutePaths.HOME)} className="medium-text" style={ getParams(RoutePaths.HOME) }><i className={Icons.NAV_HOME}/> Home</p>
                    <p onClick={() => navigate(RoutePaths.PROFILE)} className="medium-text" style={ getParams(RoutePaths.PROFILE) }><i className={Icons.NAV_PROFILE}/> Profile</p>
                    <p onClick={() => navigate(RoutePaths.CONTACT)} className="medium-text" style={ getParams(RoutePaths.CONTACT) }><i className={Icons.NAV_CONTACT}/> Contact</p>
                    <p onClick={() => navigate(RoutePaths.DASHBOARD)} className="medium-text" style={ getParams(RoutePaths.DASHBOARD) }><i className={Icons.NAV_DASHBOARD}/> Dashboard</p>
                </div>
            </div>
        </>
    );
}

export default Navigation;