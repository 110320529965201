export const RoutePaths = Object.freeze({
    LOGIN: "/login",
    
    HOME: "/",
    PROFILE: "/profile",
    POST: "/post",
    PROJECT: "/project",
    CONTACT: "/contact",

    DASHBOARD: "/dashboard",
    INBOX: "/dashboard/inbox",
    LOGS: "/dashboard/logs",
    CREATE_PROJECT: "/create-project",
    MANAGE_PROJECTS: "/manage-project",
    CREATE_LOGIN: "/create-login",
    MANAGE_FILES: "/manage-files",
    UPLOAD_FILES: "/upload-files",

    NOT_FOUND: "*"
});

export default RoutePaths;
