import '../../../styles/profile.css';

import { useEffect, useState } from 'react';
import Colors from '../../../util/Colors';
import Divider from '../../partials/generic/Divider';
import PostComponent from '../../partials/profile/PostComponent';
import PostComposer from '../../partials/profile/PostComposer';
import Button from '../../partials/generic/Button';
import axios from 'axios';

const Profile = () => {
    const [data, setData] = useState([]);
    const [session, setSession] = useState(false);
    const [category, setCategory] = useState('posts');

    axios.defaults.withCredentials = true;

    useEffect(() => {
        document.title = `${process.env.REACT_APP_TITLE} / Profile`;

        fetchSession();
        processPosts();
    }, []);

    const fetchSession = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/session`)
        .then((res) => {
            setSession(res.data.logged);
        })
        .catch((error) => console.error('Error fetching session:', error));
    }

    const processPosts = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/posts`)
        .then((res) => {
            setData(res.data.reverse());
        })
        .catch((error) => console.error('Error fetching posts:', error));
    }

    const renderPosts = () => {
        return data.sort((a, b) => b.pinned - a.pinned).map((val, index) => (
            <div key={index}>
                <PostComponent data={val} />
            </div>
        ));
    };
    

    return (
        <>
            <div className="wrapper border" style={{ borderColor: Colors.border }}>
                <div className="banner-container">
                    <img className="profile" alt='' src={process.env.REACT_APP_PROFILE_PICTURE}/>
                    <img className="banner" alt='' src={process.env.REACT_APP_PROFILE_BANNER}/>
                </div>
                <div className="button-container">
                    <Button action={() => {}} text={"Follow"} button_color='transparent' hover_color={Colors.generic.blue} rounded={true}/>
                </div>
                <div className="profile-container">
                    <p className="large-text" style={{ color: Colors.text }}>Joey61021</p>
                    <p className="medium-text" style={{ color: Colors.border }}>@joey</p>
                    <p className="small-text" style={{ marginTop: 20, color: Colors.text }}>Our greatest accomplishments cannot be behind us, because our destiny lies above us.</p>
                    <div className="flex-container" style={{ gap: 20 }}>
                        <p className="small-text" style={{ marginTop: 20, color: 'grey' }}><span style={{ color: Colors.text, fontWeight: 'bold' }}>4</span> Following</p>
                        <p className="small-text" style={{ marginTop: 20, color: 'grey' }}><span style={{ color: Colors.text, fontWeight: 'bold' }}>58</span> Followers</p>
                    </div>
                </div>
                <div className="profile-navbar">
                    <div onClick={() => setCategory('posts')} className="nav-widget">
                        <p className="medium-text widget-text">Posts</p>
                        {category === 'posts' ? (
                            <div className="widget-selected" style={{ '--underlineColor': Colors.widgets.aqua.button }}/>
                        ) : null}
                    </div>
                    <div onClick={() => setCategory('media')} className="nav-widget">
                        <p className="medium-text widget-text">Media</p>
                        {category === 'media' ? (
                            <div className="widget-selected" style={{ '--underlineColor': Colors.widgets.aqua.button }}/>
                        ) : null}
                    </div>
                    <div onClick={() => setCategory('likes')} className="nav-widget">
                        <p className="medium-text widget-text">Likes</p>
                        {category === 'likes' ? (
                            <div className="widget-selected" style={{ '--underlineColor': Colors.widgets.aqua.button }}/>
                        ) : null}
                    </div>
                    <div onClick={() => setCategory('following')} className="nav-widget">
                        <p className="medium-text widget-text">Following</p>
                        {category === 'following' ? (
                            <div className="widget-selected" style={{ '--underlineColor': Colors.widgets.aqua.button }}/>
                        ) : null}
                    </div>
                </div>
                <Divider/>
                {session ? (
                    <PostComposer profile={data.profile}/>
                ) : null}
                {category === 'posts' ? renderPosts() : null}
            </div>
        </>
    );
};

export default Profile;
