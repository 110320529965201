import '../../../styles/menu.css';

import Colors from '../../../util/Colors';
import Icons from '../../../util/Icons';
import { useState, useEffect, useRef } from 'react';

const VerificationBadge = ({children}) => {
    const [visible, setVisible] = useState(false);
    const containerRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (containerRef.current && !containerRef.current.contains(event.target)) {
                setVisible(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [containerRef]);

    return (
        <div ref={containerRef} className="inline-container" style={{ cursor: 'pointer' }}>
            {visible ? (
                <div className="menu-container white-shadow" style={{ width: 500, color: Colors.text, backgroundColor: Colors.base }}>
                    <p className="selection-header">Verified account
                        <i className={Icons.POST_VERIFIED} style={{ width: '15px', marginLeft: '10px', color: Colors.widgets.aqua.button }}/>
                    </p>
                    <p className="text">This account has been verified.</p>
                </div>
            ) : null}
            <div onClick={(e) => {
                e.stopPropagation();
                setVisible(!visible);
            }}>
                {children}
            </div>
        </div>
    );
};

export default VerificationBadge;
