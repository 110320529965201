import '../../../styles/media.css';

import { useEffect, useState } from 'react';
import Icons from '../../../util/Icons';
import { useNavigate, useParams } from 'react-router';
import { formatInt } from '../../../util/Utils';
import RoutePaths from '../../../util/RoutePaths';
import Video from '../../partials/media/Video';
import Tooltip from '../../partials/generic/Tooltip';
import Colors from '../../../util/Colors';
import axios from 'axios';

const Media = () => {
    const { postId, m } = useParams();
    const [data, setData] = useState();
    const [media, setMedia] = useState();
    const [currentMedia, setCurrentMedia] = useState(m);
    const max_scroll_items = process.env.REACT_APP_MAX_SCROLL_ITEMS;
    const [scrollPage, setScrollPage] = useState(Math.floor(m/parseInt(max_scroll_items)));

    const navigate = useNavigate();

    axios.defaults.withCredentials = true;

    useEffect(() => {
        document.title = `${process.env.REACT_APP_TITLE} / Media`;
    }, [data, scrollPage, currentMedia]);

    useEffect(() => {
        setScrollPage(Math.floor(currentMedia/parseInt(max_scroll_items)));
    }, [currentMedia]);

    useEffect(() => {
        processData();
        processMedia();
    }, [postId]);

    const processData = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/posts/${postId}`)
        .then((res) => {
            setData(res.data);
        })
        .catch((error) => console.error('Error fetching post:', error));
    }

    const processMedia = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/media/${postId}`)
        .then((res) => {
            setMedia(res.data);
        })
        .catch((error) => console.error('Error fetching media:', error));
    }

    const renderCurrentMedia = () => {
        const start = parseInt(max_scroll_items) * scrollPage;
        const end = start + parseInt(max_scroll_items);
    
        return media.files.slice(start, end).map((media, index) => (
            <img alt='' src={media} onClick={() => setCurrentMedia(index+start)} className={`media-preview shadow ${index+start === parseInt(currentMedia) ? 'selected-media' : ''}`}/>
        ));
    };

    const prevMedia = () => {
        setCurrentMedia(parseInt(currentMedia)-1 < 0 ? media.files.length-1 : parseInt(currentMedia)-1);
    }

    const nextMedia = () => {
        setCurrentMedia(parseInt(currentMedia)+1 > media.files.length-1 ? 0 : parseInt(currentMedia)+1)
    }

    return (
        <>
            {data && media ? (
                <>
                    {!media.files[parseInt(currentMedia)].endsWith(".mp4") ? (
                        <img className="background blur" style={{ opacity: .5 }} alt='' src={media.files[parseInt(currentMedia) < 0 || parseInt(currentMedia) > media.files.length ? 0 : parseInt(currentMedia)]}/>
                    ) : null}
                    <div className="scroll-body"/>
                    <div onClick={() => navigate(RoutePaths.PROFILE)} className="close-body shadow">
                        <Tooltip message={"Close"}>
                            <p className="close-widget" style={{ color: Colors.border }}><i className={Icons.ACTION_CLOSE}/></p>
                        </Tooltip>
                    </div>
                    <div className="center">
                        <div className="expanded-wrapper">
                            <div onClick={prevMedia} className="sidebar-body">
                                <Tooltip message={"Last"}>
                                    <p className="sidebar-widget" style={{ color: media.files.length > 1 ? 'white' : 'grey' }}><i className={Icons.ARROW_LEFT}/></p>
                                </Tooltip>
                            </div>
                            {media.files[parseInt(currentMedia)].endsWith(".mp4") ? (
                                <Video src={media.files[parseInt(currentMedia) < 0 || parseInt(currentMedia) > media.files.length ? 0 : parseInt(currentMedia)]} controls/>
                            ) : (
                                <img className="expanded-media shadow" alt='' src={media.files[parseInt(currentMedia) < 0 || parseInt(currentMedia) > media.files.length ? 0 : parseInt(currentMedia)]}/>
                            )}
                            <div onClick={nextMedia} className="sidebar-body">
                                <Tooltip message={"Next"}>
                                    <p className="sidebar-widget" style={{ color: media.files.length > 1 ? 'white' : 'grey' }}><i className={Icons.ARROW_RIGHT}/></p>
                                </Tooltip>
                            </div>
                        </div>
                        <div className="media-icons">
                            <button className="widget" style={{ '--contentColor': 'rgba(255, 255, 255, .5)', '--hoverColor': Colors.generic.blue }}><i className={`icon ${Icons.POST_COMMENT}`} aria-hidden="true"></i> {formatInt(1000)}</button>
                            <button className="widget" style={{ '--contentColor': 'rgba(255, 255, 255, .5)', '--hoverColor': Colors.generic.lime }}><i className={`icon ${Icons.POST_REPOST}`} aria-hidden="true"></i> {formatInt(1000)}</button>
                            <button className="widget" style={{ '--contentColor': 'rgba(255, 255, 255, .5)', '--hoverColor': Colors.generic.red }}><i className={`icon ${Icons.POST_LIKE}`} aria-hidden="true"></i> {formatInt(1000)}</button>
                        </div>
                        {media.files.length > 1 ? (
                            <div className="flex-container">
                                <div className="media-scroll">
                                    <div className="image-container">
                                        {renderCurrentMedia()}
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </div>
                </>
            ) : null}
        </>
    );
};

export default Media;
