import '../../../styles/education-card.css';

import Colors from '../../../util/Colors';
import Tooltip from '../generic/Tooltip';
import EducationPopup from './EducationPopup';
import Education from './Education';


const RenderEducationCard = ({color=Colors.widgets.aqua}) => {
    return (
        <>
            <div className="title-wrapper">
                <Tooltip message={"Elementary"}>
                    <p className="medium-text" style={{ color: 'grey' }}>Elementary</p>
                </Tooltip>
                <Tooltip message={"High School"}>
                    <p className="medium-text" style={{ color: 'grey' }}>High School</p>
                </Tooltip>
                <Tooltip message={"University"}>
                    <p className="medium-text" style={{ color: 'grey' }}>University</p>
                </Tooltip>
            </div>
            <div className="timeline-body" style={{ backgroundColor: Colors.base }}>
                <div className="timeline-progress" style={{ backgroundColor: color.primary }}/>
                <EducationPopup education={Education.ELEMETARY_EDUCATION}>
                    <div className="point-container">
                        <div className="timeline-point" style={{ backgroundColor: color.primary }}/>
                    </div>
                </EducationPopup>
                <EducationPopup education={Education.HIGH_SCHOOL}>
                    <div className="point-container">
                        <div className="timeline-point" style={{ backgroundColor: color.primary }}/>
                    </div>
                </EducationPopup>
                <EducationPopup education={Education.UNIVERSITY}>
                    <div className="point-container">
                        <div className="timeline-point" style={{ backgroundColor: color.button }}/>
                        <div className="point-overlay" style={{ '--ringColor': color.ping }}/>
                    </div>
                </EducationPopup>
            </div>
        </>
    );
}

export default RenderEducationCard;
