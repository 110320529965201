import '../../../styles/sidebar.css';

import { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import Colors from '../../../util/Colors';
import Icons from '../../../util/Icons';

const ProfileSidebar = () => {
    const [media, setMedia] = useState([]);
    const [searchSelected, setSearchSelected] = useState(false);

    const search = useRef();
    const searchContainer = useRef();

    axios.defaults.withCredentials = true;

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/media`)
        .then((res) => {
            setMedia(res.data.files.reverse());
        })
        .catch((error) => console.error('Error fetching media:', error));
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchContainer.current && !searchContainer.current.contains(event.target)) {
                setSearchSelected(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const setFocus = () => {
        setSearchSelected(true);
        search.current.focus();
    }

    return (
        <div className="sidebar-wrapper">
            <div style={{ marginLeft: 30 }}>
                <div ref={searchContainer} onClick={setFocus} className="search-container" style={{ borderColor: searchSelected ? Colors.generic.blue : Colors.border }}>
                    <i style={{ color: 'grey', padding: 10 }} className={`medium-text ${Icons.NAV_SEARCH}`}/>
                    <input ref={search} className="small-text search-body" placeholder={"Start searching..."}/>
                </div>

                <div className="media-sidebar" style={{ borderColor: Colors.border }}>
                    <p className="large-text" style={{ fontWeight: 'bold', color: Colors.text }}>Recent media</p>
                    <div className="item-container">
                        {media && media.length > 0 ? (
                            <>
                                {media.slice(0, 6).map((val, index) => (
                                    <li key={index}>
                                        <a target='_blank' href={val}>
                                            <img className="sidebar-image" alt='' src={val}/>
                                        </a>
                                    </li>
                                ))}
                            </>
                        ) : (
                            <p className="medium-text" style={{ color: Colors.border }}>No media yet...</p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProfileSidebar;
