import '../../../styles/media.css';

import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import Colors from '../../../util/Colors';
import PostComponent from '../../partials/profile/PostComponent';
import Icons from '../../../util/Icons';
import RoutePaths from '../../../util/RoutePaths';
import axios from 'axios';

const Post = () => {
    const { postId } = useParams();
    const [data, setData] = useState();

    axios.defaults.withCredentials = true;

    useEffect(() => {
        document.title = `${process.env.REACT_APP_TITLE} / Post`;

        processData();
    }, [postId]);

    const processData = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/posts/${postId}`)
        .then((res) => {
            setData(res.data);
        })
        .catch((error) => console.error('Error fetching post:', error));
    }


    return (
        <>
            {data ? (
                <div className="wrapper border" style={{ borderColor: Colors.border }}>
                    <PostComponent data={data}/>
                    <p className="medium-text center" style={{ color: Colors.border, marginTop: 50 }}>This post has no comments!</p>
                </div>
            ) : null}
        </>
    );
};

export default Post;
