import '../../../styles/input.css';

import Colors from '../../../util/Colors';
import { useRef, useState, useEffect } from "react";

const Input = ({ type="text", placeholder, setVariable, value='', limit=100, expanded=false }) => {
    const [content, setContent] = useState(value);
    const [focused, setFocused] = useState(false);
    const [invalid, setInvalid] = useState(false);
    const [interacted, setInteracted] = useState(false);

    const containerRef = useRef();
    const inputRef = useRef();

    useEffect(() => {
        setContent(value);
    }, [value]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (containerRef.current && !containerRef.current.contains(event.target)) {
                setFocused(false);
                setInvalid(content.length === 0 ? true : false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [content]);

    const handleFocus = () => {
        setFocused(true);
        setInteracted(true);
        inputRef.current.select();
        inputRef.current.focus();
    }

    const handleChange = (event) => {
        const input = event.target.value;
        if (input.length <= limit) {
            setContent(input);
            setVariable(input);
        }
    }

    const getColor = () => {
        return focused ? Colors.generic.blue : interacted && invalid ? Colors.generic.red : Colors.border;
    }

    return (
        <div className="flex-container" style={{ margin: 15 }}>
            <div ref={containerRef} className={`input-wrapper ${expanded ? 'expanded-input' : ''}`} onClick={handleFocus} style={{ borderColor: getColor() }}>
                <p className={`input-placeholder ${focused || expanded || content.length > 0 ? 'selected' : ''}`} style={{ color: focused || content.length > 0 ? Colors.generic.blue : Colors.border }}>{placeholder}</p>
                <p className="char-counter" style={{ color: Colors.border }}>{content.length} / {limit}</p>
                {expanded ? (
                    <textarea ref={inputRef} type={type} className="input-container" onChange={(e) => handleChange(e)} value={content} style={{ color: Colors.text, height: 120 }}/>
                ) : (
                    <input ref={inputRef} type={type} className="input-container" onChange={(e) => handleChange(e)} value={content} style={{ color: Colors.text }}/>
                )}
            </div>
        </div>
    );
}

export default Input;
