import './styles/style.css';
import './styles/variables.css';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ProtectedRoute from './util/ProtectedRoute.js'
import RoutePaths from './util/RoutePaths.js';
import { useEffect, useState } from 'react';
import axios from 'axios';

import Loading from './components/generic/loading.js';
import NotFound from './components/generic/not-found.js';

import Navigation from './components/partials/sidebar/Navigation.js';
import TopButton from './components/partials/home/TopButton.js';

import Login from './components/user/login.js';

import Home from './components/user/home.js';
import Project from './components/user/project.js';
import Profile from './components/user/profile/profile.js';
import ProfileSidebar from './components/partials/sidebar/ProfileSidebar.js';
import ProfileNavigation from './components/partials/sidebar/ProfileNavigation.js';
import Post from './components/user/profile/post.js';
import Media from './components/user/profile/media.js';
import Contact from './components/user/contact.js';

import Dashboard from './components/admin/dashboard.js';
import Inbox from './components/admin/messages/inbox.js';
import Logs from './components/admin/logs/logs.js';
import CreateProject from './components/admin/projects/create-project.js';
import ManageProjects from './components/admin/projects/manage-projects.js';
import CreateLogin from './components/admin/logins/create-login.js';
import ManageFiles from './components/admin/files/manage-files.js';
import UploadFiles from './components/admin/files/upload-files.js';

const App = () => {
    const [isAuth, setIsAuth] = useState(false);
    const [loading, setLoading] = useState(true);

    axios.defaults.withCredentials = true;

    useEffect(() => {
        setLoading(true);
        axios.get(`${process.env.REACT_APP_API_URL}/session`)
            .then((response) => {
                setIsAuth(response.data.logged);
            })
            .catch((error) => {
                setIsAuth(false);
                console.error('Error fetching session data:', error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    return loading ? <Loading/> : (
        <Router>
            <Routes>
                <Route element={<ProtectedRoute isAuth={isAuth ? false : true} fallback={RoutePaths.DASHBOARD}/>}>
                    <Route path={RoutePaths.LOGIN} element={<><Navigation/><Login setIsAuth={setIsAuth}/></>}/>
                </Route>

                <Route path={RoutePaths.HOME} element={<><Navigation/><TopButton/><Home/></>}/>
                <Route path={`${RoutePaths.PROJECT}/:projectId`} element={<><Navigation/><Project/></>}/>
                <Route path={RoutePaths.PROFILE} element={<><Navigation/><ProfileNavigation/><ProfileSidebar/><Profile/></>}/>
                <Route path={`${RoutePaths.POST}/:postId`} element={<><Navigation/><Post/></>}/>
                <Route path={`${RoutePaths.POST}/:postId/:m`} element={<Media/>}/>
                <Route path={RoutePaths.CONTACT} element={<><Navigation/><Contact/></>}/>

                <Route element={<ProtectedRoute isAuth={isAuth ? true : false} fallback={RoutePaths.LOGIN}/>}>
                    <Route path={RoutePaths.DASHBOARD} element={<><Navigation/><Dashboard/></>}/>
                    <Route path={RoutePaths.LOGS} element={<><Navigation/><Logs/></>}/>
                    <Route path={RoutePaths.INBOX} element={<><Navigation/><Inbox/></>}/>
                    <Route path={`${RoutePaths.CREATE_PROJECT}/:projectId?`} element={<CreateProject/>}/>
                    <Route path={RoutePaths.MANAGE_PROJECTS} element={<><Navigation/><ManageProjects/></>}/>
                    <Route path={RoutePaths.CREATE_LOGIN} element={<><Navigation/><CreateLogin/></>}/>
                    <Route path={RoutePaths.MANAGE_FILES} element={<><Navigation/><ManageFiles/></>}/>
                    <Route path={RoutePaths.UPLOAD_FILES} element={<><Navigation/><UploadFiles/></>}/>
                </Route>

                <Route path={RoutePaths.NOT_FOUND} element={<NotFound/>}/>
            </Routes>
        </Router>
    );
};

export default App;
