import '../../../styles/sidebar.css';

import axios from 'axios';
import Icons from '../../../util/Icons';
import Colors from '../../../util/Colors';

const ProfileNavigation = () => {
    axios.defaults.withCredentials = true;

    return (
        <div className="navigation-wrapper">
            <div className="align-content">
                <li style={{ '--hoverColor': Colors.base, color: Colors.text, fontWeight: 'bold' }}><i className={Icons.PROFILE_HOME}/><p>Home</p></li>
                <li style={{ '--hoverColor': Colors.base, color: Colors.text }}><i className={Icons.PROFILE_EXPLORE}/><p>Explore</p></li>
                <li style={{ '--hoverColor': Colors.base, color: Colors.text }}><i className={Icons.PROFILE_NOTIFICATIONS}/><p>Notifications</p></li>
                <li style={{ '--hoverColor': Colors.base, color: Colors.text }}><i className={Icons.PROFILE_MESSAGES}/><p>Messages</p></li>
                <li style={{ '--hoverColor': Colors.base, color: Colors.text }}><i className={Icons.PROFILE_COMMUNITIES}/><p>Communities</p></li>
                <li style={{ '--hoverColor': Colors.base, color: Colors.text }}><i className={Icons.PROFILE_PREMIUM}/><p>Premium</p></li>
                <li style={{ '--hoverColor': Colors.base, color: Colors.text }}><i className={Icons.PROFILE_PROFILE}/><p>Profile</p></li>
                <li style={{ '--hoverColor': Colors.base, color: Colors.text }}><i className={Icons.PROFILE_MORE}/><p>More</p></li>
            </div>
        </div>
    );
}

export default ProfileNavigation;
