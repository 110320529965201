import { useEffect, useState } from 'react';
import '../../../styles/top-button.css';
import Colors from '../../../util/Colors';
import Icons from '../../../util/Icons';

const TopButton = () => {
    const [pageY, setPageY] = useState();

    useEffect(() => {
        const handleScroll = () => {
            setPageY(window.scrollY);
        };
    
        window.addEventListener("scroll", handleScroll);
        handleScroll();
    
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);    

    return (
        <>
            {pageY > 0 ?(
                <div onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})} className="top-button shadow" style={{ borderColor: Colors.border }}>
                    <p className="large-text" style={{ color: Colors.text }}><i className={Icons.ARROW_UP}/></p>
                </div>
            ) : null}
        </>
    );
};

export default TopButton;
